import React, {useContext} from 'react';
import { GlobalDataContext } from '../../context/context';


// import imgDefault from '../../assets/image/placeholder.png'

const BlockCupons = ({ image1, image2, image3, image4, image5, image6, text, title, title2, image7 }) => {

const {rpdata} =useContext(GlobalDataContext);
    return (
        <div className='md:my-[150px] my-[80px] h-auto w-full flex flex-col justify-center items-center'>
            <div className='w-[85%] flex md:flex-row flex-col justify-center my-5'>
                <div className='md:w-1/2 w-[80%]'>
                    <h3>{title}</h3>
                </div>
                <div className='md:w-[40%] w-full'>
                    <p className='pb-3'>
                        {
                            text ? text :
                                <span>{`para agregar el texto -> text={'description'}`}
                                    <br />{'para agregar lista de about -> listsAbout={true}'}
                                    <br />{'para agregar lista de servicios -> listsServices={true}'}
                                </span>
                        }
                    </p>
                    {
                        title2 ? title2 :
                            <span>{`para agregar el texto -> text={'description'}`}</span>
                    }

                    <a
                        href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                    >
                   
                        <h5 className='text-[21px] textColor1'>
                            {rpdata?.dbPrincipal?.phones?.[0]?.phone}
                        </h5>
                    </a>
                </div>

            </div>
            <div className='w-[85%] flex justify-center flex-col items-center gap-4 text-center'>
          
                <h2>OUR LIFETIME DEALS...</h2>
                <div className='flex flex-col items-center space-y-10'>
                    <img src={image3} alt="imagecup" className='w-[80%]' />
                    <img src={image4} alt="imagecup" className='w-[80%]' />
                    <img src={image5} alt="imagecup" className='w-[80%]' />
                    <img src={image6} alt="imagecup" className='w-[80%]' />
                    {/* <img src={image7} alt="imagecup" className='w-[80%]' /> */}
                </div>
            </div>
        </div>
    )
}

export default BlockCupons;