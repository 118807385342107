import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
// import Busqueda from "../components/Services/Busqueda";
import BaseLayout from "../components/global/BaseLayout";
import ContentServices from "../components/Services/ContentMenu";

function OurServices() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <BaseLayout PageName="OurServices">
            <div className="md:max-w-full w-full">
                <TransparentHeader
                    headertitle="OurServices"
                    Subheader="OurServices"
                    bgimg={`${rpdata?.gallery?.[39]}`}
                    urlVideo={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Electric.mp4?alt=media&token=1f5f7df4-e652-4708-b766-b7644ee1702c"}
                />
                <ContentServices/>
            </div>
        </BaseLayout>
    );
}

export default OurServices;
