import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import BaseLayout from "../global/BaseLayout";
import TransparentHeader from "../global/TransparentHeader";
import { ButtonContent } from '../global/boton/ButtonContent'
import ContactInfo5 from "../Contact/ContactInfo_5";



function LocationServices() {
    const { rpdata } = useContext(GlobalDataContext);
    const { id } = useParams();
    // console.log(id);


    return (
        <BaseLayout PageName={`${rpdata?.works?.name}`}>
            {
                rpdata?.works?.map((item, index) => {
                    if (item.name.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase() === id) {
                        return (
                            <div key={index}>
                                <TransparentHeader
                                    // imagen aleatoria
                                    bgimg={
                                        rpdata?.gallery?.length > 1 ?
                                            rpdata?.gallery[Math.floor(Math.random() * rpdata?.gallery?.length)]
                                            : rpdata?.stock[Math.floor(Math.random() * rpdata?.stock?.length)]
                                    }
                                    headertitle={item.name}
                                    Subheader={'Service Areas'}
                                    svgClass={'svgTranspareHeader2'}
                                />
                                <div className="w-[95%] flex flex-col lg:flex-row mx-auto my-10 md:flex md:gap-5">
                                    <div className="w-full p-5 md:p-10 mb-5 rounded-3xl">
                                        <iframe
                                            src={item?.subdescription}
                                            loading="lazy"
                                            title="Cover Locations"
                                            className="w-full h-[500px]"
                                        />
                                        <h3>
                                            {
                                                rpdata?.dbSlogan[Math.floor(Math.random() * rpdata?.dbSlogan?.length)].slogan
                                            }
                                        </h3>
                                        <p className="text-justify">
                                            {item.description}
                                        </p>

                                        <ButtonContent />
                                    </div>

                                </div>
                                <ContactInfo5
                                    image1={rpdata?.gallery?.[10]}
                                    image={rpdata?.gallery?.[8]}
                                />
                            </div>
                        );
                    } else return console.log('Servicio no encontrado')

                })
            }




        </BaseLayout>
    );
}

export default LocationServices;