import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'
import Form from './Form'
import imgDefault from '../../assets/image/placeholder.png'


const ContactInfo5 = ({ image1, image }) => {

    const { rpdata } = useContext(GlobalDataContext)

    return (
        <>
            <div className="w-full flex justify-center">
                <div className="flex w-[1100px] py-24 md:flex-row flex-col text-center md:text-start">
                    <div
                        className="md:w-[50%] w-full md:px-14 px-2 bg-center bg-cover "
                        style={{ backgroundImage: `url("${rpdata ? image : imgDefault}")` }}
                    ></div>
                    <div className="md:w-[50%] w-full md:px-14 px-2">
                        <h3>Send Us A Message</h3>
                        <Form />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactInfo5;