import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'
// import { ButtonContent } from '../global/boton/ButtonContent';

const ContentMenu = (cntService) => {
    const { rpdata } = useContext(GlobalDataContext);


    return (
        <div className='w-[80%] mx-auto my-8 flex justify-center items-center flex-col'>
            {
                rpdata?.works?.map((item, index) => {
                    return (
                        <div key={index} className='w-[90%] mx-auto flex md:flex-row flex-col items-center justify-between my-3'>
                            <div className='md:w-[90%] w-full text-center md:text-start justify-center'>
                                <p className='w-[100%] md:w-[80%] border-dotted border-b-2 capitalize font-semibold text-[18px] mb-5'>{item.name}</p>
                                <p className='w-[100%] md:w-[80%] md:text-start text-center text-[#8E101F] font-semibold'>$ {item.description}</p>
                            </div>
                            <div className='md:w-[20%] w-full flex justify-center '>
                                <img
                                    src={item?.gallery?.[0]}
                                    alt='services images'
                                    className='w-[150px] h-[150px] rounded-md shadow-2xl border '
                                />
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default ContentMenu