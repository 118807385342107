import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import BlockCupons from "../components/block/BlockCupons";


function Coupons() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <BaseLayout PageName="coupons">
            <div className="md:max-w-full w-full">
                <TransparentHeader
                    headertitle="Coupons"
                    Subheader="Coupons"
                    bgimg={`${rpdata?.gallery?.[9]}`}
                />
            </div>
            <BlockCupons
                title={rpdata?.dbSlogan?.[7]?.slogan}
                title2={rpdata?.dbSlogan?.[8]?.slogan}
                text={rpdata?.dbHome?.[2]?.text}
                image1={`${rpdata?.stock?.[0]}`}
                image2={`${rpdata?.stock?.[1]}`}
                image3={`${rpdata?.stock?.[2]}`}
                image4={`${rpdata?.stock?.[3]}`}
                image5={`${rpdata?.stock?.[5]}`}
                image6={`${rpdata?.stock?.[4]}`}
                image7={`${rpdata?.stock?.[6]}`}
            />
        </BaseLayout>
    );
}

export default Coupons;
